html {
  font-size: 15px !important;
}

:root {
  --primary-blue: #2185d0;
  --primary-grey: #22262b;
  --secondary-grey: #272b30;
  --standard-text: #f5f5f5;
  --hovered-text: #eff0f4c4;
  --disabled-text: #636363;
  --muted-text: #6a6a6a;
  --muted-background: #68686d;
  --body-background: #13161a;
  --yellow-accent: #feff9d;
  --box-shadow: 2px 8px 15px 1px var(--body-background);
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-background) !important;
  color: var(--standard-text) !important;
}

h4 {
  font-size: 1.3rem !important;
}

::selection {
  color: var(--standard-text) !important;
  background: var(--primary-blue) !important;
}

::-moz-selection { /* Code for Firefox */
  color: var(--standard-text) !important;
  background: var(--primary-blue) !important;
}

.header {
  position: fixed;
  top: 0;
  text-align: center;
  background-color: var(--primary-grey) !important;
  border-bottom: 1px solid var(--primary-grey) !important;
  z-index: 9999;
  width: 100%;
  word-spacing: 4px;
}

.headerText {
  letter-spacing: 3px;
  padding: 10px 15px 7px 15px !important;
  font-weight: 900 !important;
  color: var(--primary-blue) !important;
}


.container {
  margin: 0 auto;
  padding: 15px;
  position: relative;
  top: 68px;
  max-width: 1500px;
  height: calc(100% - 100px);
}

#root {
  height: 100%;
}

.topicInput {
  width: 6rem;
}
.topicInput > input {
  color: var(--standard-text) !important;
}

.instructions {
  position: relative;
  top: 15px;
}

.resultContent {
  padding: 5px;
  border-radius: 10px;
}

.contextButton {
  font-size: 0.8rem;
  position: relative;
  top: -2px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: bold;
}

.contextButton:hover {
  color: var(--primary-blue);
}

.contextButtonDisabled {
  font-size: 0.8rem;
  position: relative;
  top: -2px;
  padding: 10px;
  cursor: default;
  text-align: center;
  color: var(--disabled-text);
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: bold;
}

.contextCounter {
  position: relative;
  top: -4px;
  padding: 10px;
  color: var(--primary-blue);
  font-weight: bold;
  text-align: center;
  cursor: default;
  word-spacing: -2px;
}

.contextCounter > span {
  position: relative;
  top: 1px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.pluralities {
  position: relative;
  top: -6px;
  left: 10px;
}

.buttonRow {
  /* padding: 6px 0 10px 0 !important; */
  padding: 0 !important;
  /* margin: 0 !important; */
}

.buttonRow > * > svg{
  position: relative;
  top: 2px;
  right: 6px;
}

.highlightSearchTerm {
  font-weight: 900;
  color: var(--yellow-accent);
}

.searchModifierButton {
  position: relative;
  right: 2px;
  top: -1px;
  float: right;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.searchModifierButton:hover {
  color: var(--hovered-text);
}

.sortSelector > svg {
  margin-left: 20px;
  position: relative;
  margin-right: 4px;
  top: 2px;
}

.sourceSelector > svg {
  position: relative;
  margin-right: 8px;
  top: 2px;
  transform: scale(1.2);
}

.result {
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: var(--primary-grey);
  border: 1px solid var(--secondary-grey);
  transition: background-color 0.05s;
}

.result:hover {
  background-color: var(--secondary-grey);
}

.articleWrapper {
  margin-top: 10px;
  margin-bottom: 24px !important;
}

.articleHead {
  height: 50px;
  margin-bottom: 10px;
}

.articleHeadContent {
  position: relative;
  padding: 0 0px 5px 11px;
  float: left;
}

.articleLinks {
  position: relative;
  top: 3px;
  color: var(--muted-text);
}
.articleLinks > a {
  color: var(--muted-text);
}
.articleLinks > a:hover {
  color: var(--primary-blue);
}
.articleLinks svg {
  position: relative;
  margin-right: 2px;
  top: 1px;
  height: 12px;
}


.articleHeadHeader {
  font-weight: bold;
}

.articleHeadTag {
  color: var(--muted-text);
  position: relative;
  left: 6px;
}

.searchBar {
  border-radius: 10px;
  margin-bottom: 10px;
  text-align: center;
  padding: 7px 0 7px 0;
}
.searchBar Button {
  border-radius: 10px !important;
}
.searchAfterUnScrolled > .searchBar {
  background-color: var(--primary-grey);
  transition: background-color 0.15s;
  border: 1px solid var(--secondary-grey);
  }
.searchAfterScrolled > .searchBar {
  background-color: var(--body-background);
  transition: background-color 0.15s;
  border: 1px solid var(--secondary-grey);
}
.searchInitial > .searchBar {
  background-color: var(--primary-grey);
  transition: background-color 0.15s;
  border: 1px solid var(--secondary-grey);
}

.searchInitial {
  position: absolute;
  top: 40%;
  margin: 0;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  padding-top: 30px;
  padding-bottom: 15px;
}

.searchAfter {
  margin: 0;
  z-index: 1000;
  padding-top: 5px;
  padding-bottom: 0px;
}
.searchAfterScrolled {
  position: fixed;
  top: 41px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 0 0 10px 10px;
  -webkit-box-shadow: var(--box-shadow);
  -moz-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  background-color: var(--primary-grey);
  border: 1px solid var(--secondary-grey);
  transition: border-width 0.15s, padding 0.05s, background-color 0.15s;
}
.searchAfterUnScrolled {
  position: fixed;
  top: 53px;
  border-radius: 10px;
  background-color: var(--body-background);
  transition: border-width 0.15s, padding 0.05s, background-color 0.15s;
}

.searchDivider {
  padding: 0;
  margin-top: 0 !important;
}
.quoteWithinArticleContainer {
  position: absolute;
  width: 100%;
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 0;
}

.quoteWithinArticle {
  border-radius: 10px;
  padding: 15px;
  background-color: var(--primary-grey);
  color: var(--muted-background);
  border: 1px solid var(--primary-grey);
  width: calc(100% - 20px);
}

.quoteWithinArticleHighlight {
  display: block;
  position: relative;
  left: -20px;
  padding: 15px;
  margin: -2px 0 -2px 0;
  width: calc(100% + 40px);
  z-index: 1;
  border-radius: 10px;
  color: var(--standard-text);
  background-color: var(--secondary-grey);
  font-size: 1.2rem;
  -webkit-box-shadow: var(--box-shadow);
  -moz-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
}

.backFromQuote:hover {
  color: var(--primary-blue);
}

.moveDown {
  position: relative;
  top: 68px;
}

.navigationButtons {
  margin-top: 10px;
  margin-bottom: 5px;
  padding-bottom: 0;
}

.navigationButtons .checkbox label::before, label::after {
  position: relative;
  top: 2px !important;
  height: 1.2rem !important;
  width: 3.2rem !important;
}
.navigationButtons .checkbox label::after {
  width: 1.2rem !important;
}

.overrideLinkStyling {
  color: var(--standard-text);
}
.overrideLinkStyling:hover {
  color: var(--standard-text);
}

.filledCount {
  display: inline-block;
  background-color: var(--primary-blue);
  height: 4px;
  width: 4px;
  border-radius: 2px;
  margin: 0 3px 0 3px;
}

.unFilledCount {
  display: inline-block;
  background-color: var(--muted-background);
  height: 4px;
  width: 4px;
  border-radius: 2px;
  margin: 0 3px 0 3px;
}

.articleTypeSymbol {
  background-color: var(--primary-grey);
  border: 1px solid var(--secondary-grey);
  border-radius: 10px;
  height: 50px;
  width: 50px;
  float: left;
  text-align: center;
}

.articleTypeSymbol > svg {
  height: 50px;
}

.speechSymbol {
  font-size: 0.9rem;
}
.tweetSymbol {
  font-size: 1.2rem;
}
.statementSymbol {
  font-size: 1.2rem;
}
.interviewSymbol {
  font-size: 1.2rem;
}



/* EVERYTHING FOR DROPDOWN */

/* INITIAL */

.searchInitial .personsSelect {
  color: #FFF !important;
  background-color: var(--body-background) !important;
  border: 1px solid var(--primary-grey) !important;
  cursor: pointer !important;
  border-radius: 10px !important;
}

.searchInitial .personsSelect > input {
  color: #fff !important;
  cursor: pointer !important;
}
.searchInitial .personsSelect .item:hover {
  background-color: var(--body-background) !important;
}
.searchInitial .personsSelect > div {
  /* background-color: var(--primary-grey) !important; */
  border: 0px solid var(--primary-grey) !important;
}
.searchInitial .personsSelect > div > div {
  color: #fff !important;
  background-color: var(--primary-grey) !important;
  border-top: 1px solid var(--primary-grey) !important;
  border-bottom: 1px solid var(--primary-grey) !important;
  /* border-radius: 0 0 .28571429rem .28571429rem !important; */
}

.searchInitial .personsSelect > div[role=listbox] {
  background-color: var(--primary-grey) !important;
  /* does scroll bar */
  background-color: var(--primary-grey) !important;
  box-shadow: var(--box-shadow) !important;
  border-radius: 0px 0px 10px 10px !important;
}

/* UNSCROLLED */

.searchAfterUnScrolled .personsSelect {
  color: #FFF !important;
  background-color: var(--body-background) !important;
  border: 1px solid var(--primary-grey) !important;
  cursor: pointer !important;
  border-radius: 10px !important;
}

.searchAfterUnScrolled .personsSelect > input {
  color: #fff !important;
  cursor: pointer !important;
}
.searchAfterUnScrolled .personsSelect .item:hover {
  background-color: var(--body-background) !important;
}
.searchAfterUnScrolled .personsSelect > div {
  /* background-color: var(--primary-grey) !important; */
  border: 0px solid var(--primary-grey) !important;
}
.searchAfterUnScrolled .personsSelect > div > div {
  color: #fff !important;
  background-color: var(--primary-grey) !important;
  border-top: 1px solid var(--primary-grey) !important;
  border-bottom: 1px solid var(--primary-grey) !important;
  /* border-radius: 0 0 .28571429rem .28571429rem !important; */
}

.searchAfterUnScrolled .personsSelect > div[role=listbox] {
  background-color: var(--primary-grey) !important;
  /* does scroll bar */
  background-color: var(--primary-grey) !important;
  box-shadow: var(--box-shadow) !important;
  border-radius: 0px 0px 10px 10px !important;
}

/* SCROLLED */

.searchAfterScrolled .personsSelect {
  color: #FFF !important;
  background-color: var(--primary-grey) !important;
  border: 1px solid var(--primary-grey) !important;
  cursor: pointer !important;
  border-radius: 10px !important;
}

.searchAfterScrolled .personsSelect > input {
  color: #fff !important;
  cursor: pointer !important;
}
.searchAfterScrolled .personsSelect .item:hover {
  background-color: var(--primary-grey) !important;
}
.searchAfterScrolled .personsSelect > div {
  /* background-color: var(--primary-grey) !important; */
  border: 0px solid var(--primary-grey) !important;
}
.searchAfterScrolled .personsSelect > div > div {
  color: #fff !important;
  background-color: var(--body-background) !important;
  border-top: 1px solid var(--body-background) !important;
  border-bottom: 1px solid var(--body-background) !important;
  /* border-radius: 0 0 .28571429rem .28571429rem !important; */
}

.searchAfterScrolled .personsSelect > div[role=listbox] {
  background-color: var(--primary-grey) !important;
  /* does scroll bar */
  background-color: var(--primary-grey) !important;
  box-shadow: var(--box-shadow) !important;
  border-radius: 0px 0px 10px 10px !important;
}
.d-none {
  display: none;
}

.noResults {
  margin-top: 35px;
  color: var(--muted-background);
  /* font-size: 1.1rem; */
  text-align: center;
  line-height: 2.5rem;
}

.noResults > svg {
  height: 5rem;
  width: 5rem;
}

.noResults .sourcesSearched {
  line-height: 1.9rem;
}

.reportAProblem:hover {
  color: #d40404 !important;
}